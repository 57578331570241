.padding {
  padding: 0 10px;
}

.noPadding {
  padding: 0;
}

.marginRight {
  margin-right: 5px;
}

.marginBottom {
  margin-bottom: 10px;
}

.border {
  box-shadow:0px 0px 0px 1px #D9D9D9 inset;
  border-radius: 2px;
  background-color: #FAFAFA;
  width: 100%;
  margin-right: 10px;
  display: flex;
}

.border .nameInput {
  min-width: 235px;
  display: inline;
  background: white;
  flex: 1;
}

.border .keywordInput {
  min-width: 375px;
  display: inline;
  background: white;
  flex: 1;
}

.disable {
  background: #F5F5F5;
  color: #B8B8B8;
}

.formBody {
  padding: 10px;
  margin: 10px 0;
}

.txt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient:vertical;
  padding-left: 10px;
}