.side {
  border-right: 1px solid #f0f0f0;
  position: relative;
}

.menu {
  border-right: none;
}

.logoBox {
  width: 100%;
  color: rgba(255, 255, 255, 0.65);
  font-weight: 700;
  text-align: center;
  margin: 20px 0 5px 0;
}

.logo {
  width: 30px;
  height: 30px;
  margin: 10px 0;
}

.title {
  color: rgba(255, 255, 255, 0.65);
  opacity: 1;
  transition: opacity 0.6s cubic-bezier(0.645, 0.045, 0.355, 1), margin-left 0.3s, color 0.3s;
}

.titleCollapsed {
  opacity: 0;
  margin-left: 0;
}

.userBox {
  padding-left: 19px;
  width: 100%;
  margin: 4px 0;
  text-overflow: ellipsis;
}

.userContent {
  color: rgba(255, 255, 255, 0.65);
  opacity: 1;
  margin-left: 5px;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin-left 0.3s, color 0.3s;
}

.userContentCollapsed {
  opacity: 0;
  margin-left: 0;
}

.collapseBtn {
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0 8px;
  position: absolute;
  bottom: 10px;
  color: #f0f0f0;
}

.center {
  justify-content: center;
}
