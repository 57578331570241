.tab {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
}

.txt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: 100%;
  overflow-wrap: break-word;
  padding: 0 10px;
}

.box {
  border: 1px solid #D9D9D9;
  margin-bottom: 10px;
  padding: 10px;
}

.select {
  width: 200px;
  margin-right: 10px;
}

.mb {
  margin-bottom: 10px;
}

.mr {
  margin-right: 10px;
}

.red {
  color: red;
}

.input {
  width: 620px;
}

.title {
  margin-top: 5px;
}

.notComplete,
.error {
  color: red;
}

.warn {
  color: #FF9314
}

.warnTxt {
  margin-top: -5px;
}