.tab {
  height: calc(100vh - 60px);
  overflow-y: scroll;
}

.title {
  font-size: 16px;
  margin-bottom: 10px;
}

.extra {
  color: rgba(0, 0, 0, 0.45);
  margin-left: 8px;
  margin-bottom: 20px;
}

.dot {
  width: 10px;
  height: 10px;
  margin-right: 7px;
  border-radius: 50%;
}

.light {
  background-color: #52C41A;
}

.medium {
  background-color: #FAAD14;
}

.heavy {
  background-color: #FF4D4F;
}

.mb {
  margin-bottom: 20px;
}