.box {
  border: 1px solid #aaa;
  padding: 10px;
  padding-bottom: 0;
  margin-bottom: 10px;
}

.input {
  width: 600px;
}

.selector {
  margin-bottom: 5px;
  margin-right: 5px;
}