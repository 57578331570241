.margin {
  margin: 10px;
}

.extra {
  margin-top: -5px;
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.45);
}
 
.title {
  width: 180px;
  text-align: right;
}

.star {
  display: inline;
  color: #FF4D4F;
  margin-right: 5px;
}

.colRight {
  width: 300px;
}

.warn {
  color: #FF4D4F;
  margin-left: 64px;
  margin-top: -15px;
}

.spinningText {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}