.alignCenter {
  text-align: center;
}

.border {
  border: 1px solid #F0F0F0;
}

.borderBottom {
  border-bottom: 1px solid #F0F0F0;
}

.note {
  color:  rgb(177, 177, 177);
}

.marginLeft {
  margin-left: 10px;
}