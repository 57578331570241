.mb {
  margin-bottom: 20px;
}

.mr {
  margin-right: 10px;
}

.padding {
  padding: 0 10px;
}

.panelHeader {
  color: #FFF;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  background-color: #F0F0F0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.boxHeader {
  background-color: #FAFAFA;
  padding: 10px;
}

.table {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.row {
  border: 1px solid #DDD;
  box-sizing: border-box;
}

.cell {
  border: 1px solid #F0F0F0;
  overflow-wrap: break-word;
  box-sizing: border-box;
  background-color: #E6F7FF;
}

.leftCol {
  background: #E6F7FF;
  width: 240px;
  display: flex;
  align-items: center;
}

.rightCol {
  width: calc(100% - 240px);
}

.cell2 {
  border: 1px solid #F0F0F0;
  overflow-wrap: break-word;
  box-sizing: border-box;
  background-color: #FFF;
}

.select {
  width: 300px;
}

.pushRight {
  margin-right: auto;
}