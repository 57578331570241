.margin {
  margin: 10px;
}

.marginBottom {
  margin-bottom: 10px;
}

.mr {
  margin-right: 10px;
}

.extra {
  margin-top: -5px;
  margin-left: 160px;
  color: rgba(0, 0, 0, 0.45);
}

.treeSelect {
  flex: 1;
}

.select {
  width: 240px;
  margin-right: 10px;
}

.title {
  width: 155px;
  text-align: right;
  margin-bottom: 5px;
}

.red {
  color: red;
  margin-right: 5px;
}

.collapseBody {
  min-height: 100px;
  padding: 10px;
}

.collapse {
  margin: 30px 0;
  max-width: 1300px;
}

.btns {
  margin-top: 50px;
}

.col {
  display: flex;
  flex-direction: column;
}

.flex_row_end {
  display: flex;
  justify-content: flex-end;
}

.tagBox {
  max-width: 1300px;
}