.txt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
}

.exclamationIcon {
  color: red;
  margin-top: 10px;
  margin-right: 10px;
}

.warningIcon {
  color: #FF9314;
  margin-top: 10px;
  margin-right: 10px;
}

.marginBottom {
  margin-bottom: 3px;
}

.error {
  color: red;
}

.warn {
  color: #FF9314;
}



.padding {
  padding: 0 10px;
}

.noPadding {
  padding: 0;
}

.marginRight {
  margin-right: 5px;
}

.marginBottom {
  margin-bottom: 10px;
}

.border {
  box-shadow:0px 0px 0px 1px #D9D9D9 inset;
  border-radius: 2px;
  background-color: #FAFAFA;
  width: 100%;
  margin-right: 10px;
  display: flex;
}

.border .nameInput {
  min-width: 235px;
  display: inline;
  background: white;
  flex: 1;
}

.border .keywordInput {
  min-width: 375px;
  display: inline;
  background: white;
  flex: 1;
}
