.tableContainer {
  width: 100%;
  position: relative;
}

.mb {
  margin-bottom: 10px;
}

.mr {
  margin-right: 5px;
}

.selectedBox {
  background-color: #FFF1F0;
  border: 1px solid #FCD0CB;
  padding-left: 20px;
  margin-bottom: 10px;
}

.fixedBtn {
  position: fixed;
  right: 30px;
  bottom: 20px;
}

.yellowBtn {
  background-color: #FADB14;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  border: none;
  color: #000;
}

.yellowBtn:active,
.yellowBtn:focus,
.yellowBtn::selection,
.yellowBtn:hover {
  background-color: #FADB14;
  color: #000;
}