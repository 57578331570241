.form {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search {
  flex: 1;
  display: flex;
  margin-right: 20px;
}

.searchInput {
  flex: 1;
  margin-bottom: 2px;
}

.marginRightTen {
  margin-right: 10px;
}

.marginRightTwenty {
  margin-right: 20px;
}

.marginBottom {
  margin-bottom: 2px;
  margin-right: 20px;
}