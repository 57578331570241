.dot {
  width: 10px;
  height: 10px;
  margin-right: 7px;
  border-radius: 50%;
}

.mr {
  margin-right: 10px;
}

.mb {
  margin-bottom: 5px;
}

.txt {
  margin-left: 5px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
  overflow-wrap: break-word;
}

.select {
  width: 100px;
}

.box {
  border: 1px solid #aaa;
  padding: 10px;
  margin: 10px 0;
}