.txt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: 100%;
  overflow-wrap: break-word;
  padding: 0 10px;
  text-align: center;
}

.marginBottom {
  margin-bottom: 10px;
}

.marginRight {
  margin-right: 10px;
}