.box {
  border: 1px solid #aaa;
  padding: 10px;
  margin-bottom: 10px;
}

.mb {
  margin-bottom: 5px;
}

.mr {
  margin-right: 10px;
}

.input {
  width: 600px;
}

.official {
  color: #52C41A;
  background: #F6FFED;;
  border: 1px solid #B7EB8F;
  margin-right: 5px;
  padding: 3px 5px;
  height: 32px;
  border-radius: 2px;
}

.audience {
  color: #FA8C16;
  background: #FFF7E6;
  border: 1px solid #FFD591;
  margin-right: 5px;
  padding: 3px 5px;
  height: 32px;
  border-radius: 2px;
}