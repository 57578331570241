.box {
  margin: 30px 0;
}

.header {
  color: #FFF;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subHeader {
  background: #565656;
  color: #FFF;
  display: flex;
  justify-content: center;
  padding: 6px 0;
  position: relative;
  height: 36px;
}


.content {
  background-color: #fff;
  padding: 5px;
}

.dark {
  background-color: #FAFAFA;
  text-align: center;
  border-bottom: 1px solid #ededed;
  padding: 3px 0;
}

.cell {
  text-align: center;
  border-bottom: 1px solid #ededed;
  padding: 3px 0;
}

.divider {
  height: 25px;
  padding: 0;
  margin: 0;
}

.rightCol {
  margin-left: 10px;
  width: 18%;
}
