.padding {
  padding: 0 10px;
}

.marginRight {
  margin-right: 5px;
}

.marginBottom {
  margin-bottom: 10px;
}

.paddingLeft {
  padding-left: 12px;
}

.disable {
  background: #F5F5F5;
  color: #B8B8B8;
}

.txt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tableContainer {
  width: 100%;
  position: relative;
}