.box {
  border: 1px solid #aaa;
  padding: 10px;
  margin: 10px 0;
}

.input {
  width: 600px;
}

.mr {
  margin-right: 10px;
}

.mb {
  margin-bottom: 5px;
}

.date {
  width: 300px;
}
