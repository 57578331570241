.header {
    width: 100%;
    background-color: #DCE0E6;
    text-align: center;
    line-height: 2.5;
    font-weight: 600;
    position: relative;
}

.headerLeft {
    position: absolute;
    top: 0;
    left: 10px;
}

.headerRight {
    position: absolute;
    top: 0;
    right: 0;
}

.content {
    padding: 10px 15px;
}

.flexContent {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 35px);
}

.title {
    color: #565656;
    font-size: 12px;
    margin-top: 10px;
    margin-right: 5px;
}

.card {
    border: 1px solid #F0F0F0;
}

.settingCard {
    border: 1px solid #F0F0F0;
    text-align: center;
    margin: 0 auto;
    padding: 20px 0;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardTitle {
    font-size: 16px;
    margin-bottom: 10px;
}

.buttonRow {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.export {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    background-color: #1890FF;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
}

.mt {
    margin-top: 20px;
}

.tip {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.tipBottom {
    margin-top: 10px;
}

.ref {
    margin-top: 5px;
    width: fit-content;
    padding: 2px;
    border: #FFA39E 1px solid;
    background-color: #FFF1F0;
    color: #FF0000;
}

.numberIcon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 12px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: #2685ff 1px solid;
    background-color: #ffffff;
    color: #2685ff;
    margin-right: 5px;
}

.numberIconHasRef {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 12px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: #FFA39E 1px solid;
    background-color: #FFF1F0;
    color: #FF0000;
    margin-right: 5px;
}

.featureSets {
    display: flex;
    flex-direction: row;
}

.tooltip {
    margin-top: 10px;
}