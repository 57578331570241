.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}

.center {
  display: flex;
  align-items: center;
  width: 300px;
  flex-direction: column;
}


.title {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 50px;
}

.img {
  margin-bottom: 30px;
}

.btn {
  height: 50px;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}