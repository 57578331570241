.box {
  border: 1px solid #aaa;
  padding: 10px;
  margin: 10px 0;
}

.input {
  width: 600px;
}

.mr {
  margin-right: 10px;
}

.mrl {
  margin-right: 25px;
}

.mb {
  margin-bottom: 5px;
}

.select {
  width: 200px;
}
