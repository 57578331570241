.tab {
  height: calc(100vh - 120px);
  overflow-y: scroll;
}

.margin {
  margin: 10px;
}

.mr {
  margin-right: 10px;
}

.mb {
  margin-bottom: 10px;
}

.box {
  padding: 10px;
  border: 1px solid #F0F0F0;
  margin-bottom: 10px;
}

.extra {
  margin-top: -10px;
  margin-left: 150px;
  color: rgba(0, 0, 0, 0.45);
}

.treeSelect {
  width: 300px;
}

.select {
  width: 235px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.red {
  color: red;
  margin-right: 5px;
}

.collapseBody {
  min-height: 50px;
  padding: 10px;
}

.collapse {
  margin: 10px 0;
  max-width: 1300px;
}

.btns {
  margin-top: 50px;
}

.txt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: 100%;
  overflow-wrap: break-word;
  padding: 0 10px;
}

.center {
  text-align: center;
}

.hyperlink {
  color: #1890ff;
  cursor: pointer;
}

.dot {
  width: 10px;
  height: 10px;
  margin-right: 7px;
  border-radius: 50%;
}

.positive {
  background-color: #52C41A;
}

.neutral {
  background-color: #D9D9D9;
}

.negative {
  background-color: #FF4D4F;
}

.yellowBtn {
  background-color: #FADB14;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  border: none;
  color: #000;
}

.yellowBtn:active,
.yellowBtn:focus,
.yellowBtn::selection,
.yellowBtn:hover {
  background-color: #FADB14;
  color: #000;
}