.pageContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75);
}

.logo {
  width: 150px;
}

.mainText {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5;

  color: #fff;
}

.desc {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;

  color: #fff;
  text-align: center;
}