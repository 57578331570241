.padding {
  padding: 0 10px;
}


.marginRight {
  margin-right: 5px;
}

.border {
  box-shadow:0px 0px 0px 1px #D9D9D9 inset;
  border-radius: 2px;
  background-color: #FAFAFA;
  width: 100%;
  margin-right: 10px;
  display: flex;
}

.border .nameInput {
  min-width: 200px;
  display: inline;
  background: white;
  flex: 1;
}

.border .keywordInput {
  min-width: 300px;
  display: inline;
  background: white;
  flex: 1;
}

.txt {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  min-height: 32px;
}

