.margin {
  margin: 10px;
}

.select {
  min-width: 300px;
}

.extra {
  margin-top: -5px;
  margin-left: 80px;
  color: rgba(0, 0, 0, 0.45);
}
