
.border {
  border: 1px solid #F0F0F0;
  margin-bottom: 10px;
}

.borderBottom {
  border-bottom: 1px solid #F0F0F0;
  padding: 5px 0;
}

.note {
  color:  rgb(177, 177, 177);
}

.marginLeft {
  margin-left: 10px;
}


.modal {
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marginBotttom {
  margin-bottom: 5px;
}

.row {
  height: 32px;
}

.padding {
  padding: 5px 10px;
}