.modal {
  background-color: rgba(245, 245, 245, 0.85);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010;
}

.error {
  color: red;
}

.warn {
  color: orange
}