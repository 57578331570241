.border {
  border: 1px solid #F0F0F0;
}

.notComplete,
.error {
  color: red;
}

.warn {
  color: #FF9314
}

.modal {
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010;
}

.warnTxt {
  margin-left: 110px;
  margin-top: 5px;
}

.keyword {
  width: calc(100% - 135px);
}

.name {
  width: calc(100% - 80px);
}

.marginBottom {
  margin-bottom: 30px;
}