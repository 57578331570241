.margin {
  margin: 15px;
}

.extra {
  color: rgba(0, 0, 0, 0.45);
  margin: 10px;
  margin-top: -10px;
  margin-left: 135px;
}

.tabBody {
  height: calc(100vh - 160px);
  position: relative;
}

.submitBtn {
  position: absolute !important;
  bottom: 0px;
  left: 10px;
}