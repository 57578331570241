@import '~antd/dist/antd.less';

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

input, textarea {
  font-size: initial;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-break: normal; /* anywhere */
}

.nowrap {
  word-wrap: nowrap;
  white-space: nowrap;
}

.ant-tabs > .ant-tabs-nav {
  margin-bottom: 8px;
}

.ant-tabs-tab {
  padding: 8px 0;
}

div.ant-typography, .ant-typography p {
  margin: 0;
}

.ant-switch-checked {
  background-color: #FF9314;
}

td.ant-table-column-sort,
td div.ant-typography{
  background-color: inherit;
}

#modal-root {
  position: relative;
  z-index: 999;
}

.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding: 5px 12px;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ant-btn {
  white-space: unset;
}

@primary-color: #1890ff;