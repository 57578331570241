.txt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.content {
  width: 100%;
}

.empty {
  height: 40vh;
  padding: 10% 0;
}