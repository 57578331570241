.btn {
  background-color: #FADB14;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  border: none;
}

.btn:active,
.btn:focus,
.btn::selection,
.btn:hover {
  background-color: #FADB14;
  color: #000;
}
