.txt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.alignCenter {
  text-align: center;
}

.border {
  border: 1px solid #F0F0F0;
  margin-bottom: 10px;
}

.borderBottom {
  border-bottom: 1px solid #F0F0F0;
}

.note {
  color:  rgb(177, 177, 177);
}

.marginLeft {
  margin-left: 10px;
}

.notComplete,
.error {
  color: red;
}

.warn {
  color: #FF9314
}

.modal {
  background-color: rgba(245,245,245,0.85);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010;
}

.marginBotttom {
  margin-bottom: 5px;
}

.row {
  height: 32px;
}

.padding {
  padding: 5px 10px;
}