.header {
    width: 100%;
    background-color: #DCE0E6;
    text-align: center;
    line-height: 2.5;
    font-weight: 600;
    position: relative;
}

.headerLeft {
    position: absolute;
    top: 0;
    left: 10px;
}

.headerRight {
    position: absolute;
    top: 0;
    right: 0;
}

.content {
    padding: 10px 15px;
    padding-top: 0;
}

.title {
    color: #565656;
    font-size: 12px;
    margin-top: 10px;
    margin-right: 5px;
}

.card {
    border: 1px solid #F0F0F0;
    width: 100%;
    margin-bottom: 15px;
}

.mt {
    margin-top: 10px;
}
