.header {
  color: #FFF;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subHeader {
  background: #565656;
  color: #FFF;
  display: flex;
  justify-content: center;
  padding: 6px 0;
  position: relative;
  height: 36px;
}

.warn {
  position: absolute;
  right: 0px;
  top: 2px;
  color: #FAAD14;
  display: inline-block;
}

.inline {
  display: inline-block;
}

.marginRight {
  margin-right: 5px;
}

.reloadIcon {
  background-color: #FFF;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  padding-top: 2px;
}

.title {
  background-color: #C4C4C4;
  display: flex;
  justify-content: center;
  margin: 3px 0;
}

.card {
  text-align: center;
  background-color: #FFF;
  padding-bottom: 5px;
}

.card + .card {
  margin-left: 10px;
}

.text {
  color: rgba(0, 0, 0, 0.45);
  margin: 5px 0px;
}

.content {
  background-color: #e3e3e3;
  padding: 5px;
  width: 100%;
}